/*
  Place Mod
*/

.place {
  width: 100%;

}

.place-banner {
	width: 100%;
	height: 150px;
	border-radius: 5px;
	box-shadow: inset 0px 0px 5px rgba(0,0,0,0.075);
	margin-bottom: 15px;
  background: var(--image_place) center center / 64px 64px no-repeat;
  background-color: rgba(140, 135, 120, 1);
  opacity: 0.25;
}

.ps-text {
  font-size: 15px;
  color: #666;
  text-align: justify;
}
