/*
  © R. 2023
*/

.map {
  padding-top: 12px;
  width: 100%;
}

.mapMark {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  background-color: #78278E;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
}
