/*
  Meetposter Web
  © R. 2024
*/

.ev-list {
  width: 100%;
  border: 1px dotted #ccc;
  margin: 10px;
  padding: 5px;
  min-height: 200px;
  cursor: pointer;
  transition: all 0.05s;
  background-color: #fff;
}

.ev-card {
  width: 222px;
  height: 290px;
  border: 1px dotted #aaa;
  margin: 10px;
  flex-grow: 0;
  cursor: pointer;
  flex-direction: column;
  transition: all 0.05s;
  background-color: #fff;
}

.ev-card > .ev-name {
  height: 45px;
  line-height: 22px;
  font-size: 14px;
}

.ev-card > .ev-image {
  margin: 10px;
}

.ev-image {
  flex-basis: 150px;
  max-width: 200px;
  height: 160px;
  border: 1px dotted #000;
  background: var(--image_event) center center / 96px 64px no-repeat;
  opacity: 0.25;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: rgba(140, 135, 120, 1);
  margin: 5px;
}

.ev-details {
  flex-grow: 1;
  margin: 20px 0;
  flex-direction: column;
  position: relative;
  flex-basis: 65%;
  flex-shrink: 1;
  margin: 10px 5px;
}

.ev-name {
  width: 100%;
  height: 25px;
  padding: 0px 10px;
  margin-bottom: 10px;
  font-size: 17px;
  text-transform: capitalize;
  color: rgb(140, 140, 140);
  font-family: 'Roboto-Medium';
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: all 0.2s ease;
}

.ev-card:hover .ev-name, .ev-list:hover .ev-name, .ev-card:hover {
  border-color: rgba(130,39,132, 0.75);
  color: rgb(130,39,132);
}

.ev-descr {
  font-size: 13px;
  padding: 5px 10px 0;
  color: #999;
  margin-bottom: 30px;
  text-align: justify;
}

.ev-location {
  font-size: 13px;
  color: rgb(150, 150, 150);
  padding: 5px 30px 0px 28px;
  margin-bottom: 10px;
  width: 100%;
  flex-grow: 0;
  line-height: 18px;
  position: relative;
  background: var(--image_marker) 9px 8px / 15px 15px no-repeat;
}

.ev-price {
  font-size: 16px;
  font-family: 'Roboto-Medium';
  padding: 1px 10px 0px;
  width: 100px;
  height: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
  justify-content: right;
  color: rgb(110, 110, 110);
}
