/*
  © R. 2024
*/

.InputText {
	flex-grow: 1;
	min-width: 100px;
	height: 34px;
	padding: 0px 9px;
	margin: 0px 0px 12px 0px;
	cursor: text;
	border: 1px solid rgb(217, 223, 233);
	border-radius: 2px;
	color: rgb(51, 55, 64);
	background-color: transparent;
	background-color: #fff;
	font-size: 14px;
}

.InputText > option {
	color: rgb(51, 55, 64);
	background-color: #fff;
}

.InputError {
	width: 100%;
	height: 16px;
	font-size: 12px;
	color: rgba(187, 45, 45,1);
	margin: -2px 0px 7px;
}

.InputErrorBorder {
	border: 1px solid rgba(187, 45, 45,1);
}

.inputLabel {
	font-size: 13px;
	margin: 0;
	font-weight: 400;
	color: #5F5F5F;
	width: 100%;
	text-align: left;
	padding: 5px 0px 7px 0px;
	height: 27px;
}

.InputMulti {
	resize: none;
	padding-top: 7px;
	padding-bottom: 7px;
	line-height: 19px;
}

/*
	Input Bg Images,
*/

.InputSearch {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B0B0B0' viewBox='0 0 512 512'%3E%3Cpath d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 13px 13px;
	background-position: 9px 9px;
	padding-left: 30px;
}

.InputSelector {
	appearance: none;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNLjAxOCAwaDIwYTIgMiAwIDAgMSAyIDJ2MjhhMiAyIDAgMCAxLTIgMmgtMjBWMHoiIGZpbGw9IiNGQUZBRkIiLz48ZyBmaWxsLXJ1bGU9Im5vbnplcm8iIGZpbGw9IiNCM0I1QjkiPjxwYXRoIGQ9Ik0xNC4wMTggMTguMzc1YS4zNi4zNiAwIDAgMS0uMTEyLjI2NGwtMi42MjUgMi42MjVhLjM2LjM2IDAgMCAxLS4yNjMuMTExLjM2LjM2IDAgMCAxLS4yNjQtLjExMWwtMi42MjUtMi42MjVhLjM2LjM2IDAgMCAxLS4xMTEtLjI2NC4zNi4zNiAwIDAgMSAuMTExLS4yNjQuMzYuMzYgMCAwIDEgLjI2NC0uMTExaDUuMjVhLjM2LjM2IDAgMCAxIC4yNjMuMTExLjM2LjM2IDAgMCAxIC4xMTIuMjY0ek04LjAxOCAxNWEuMzYuMzYgMCAwIDEgLjExMS0uMjY0bDIuNjI1LTIuNjI1YS4zNi4zNiAwIDAgMSAuMjY0LS4xMTEuMzYuMzYgMCAwIDEgLjI2My4xMTFsMi42MjUgMi42MjVhLjM2LjM2IDAgMCAxIC4xMTIuMjY0LjM2LjM2IDAgMCAxLS4xMTIuMjY0LjM2LjM2IDAgMCAxLS4yNjMuMTExaC01LjI1YS4zNi4zNiAwIDAgMS0uMjY0LS4xMTEuMzYuMzYgMCAwIDEtLjExMS0uMjY0eiIvPjwvZz48L2c+PC9zdmc+Cg==");
	background-repeat: no-repeat;
	background-position: right center;
	cursor: default;
}

.InputTime {
	margin-left: 10px;
	background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B0B0B0' viewBox='0 0 448 512'%3E%3Cpath d='M256 512C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256s-114.6 256-256 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 15px 13px;
	background-position: 6px 9px;
	padding-left: 26px;
	min-width: 110px !important;
	max-width: 110px;
}

.fileimage {
	width: 36px;
	height: 36px;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0px 0px 5px rgba(0,0,0,0.025);
	background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23822784' viewBox='0 0 448 512'%3E%3Cpath d='M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 19px 18px;
	background-position: 7px 9px;
	cursor: pointer;
	border: 1px solid rgba(130, 39, 132,0.75);
	position: absolute;
	z-index: 300;
}

.fileimage > * {
	color: #EEE;
	width: auto;
	padding-bottom: 1px;
	text-shadow: 0px 0px 10px rgba(0,0,0,0.7);
	font-size: 20px;
}

.flip-box {
	width: 100px;
	height: 28px;
	background-color: rgba(225,25,25,0.7);
	position: relative;
	border-radius: 3px;
	transition: all 0.4s ease;
	margin-bottom: 13px;
	margin-top: 3px;
	cursor: pointer;
	margin-right: auto;
}

.flip-switch {
	width: 50px;
	height: 28px;
	border: 1px solid rgb(217, 223, 233);
	background-color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	transition: all 0.4s ease;
	border-radius: 3px;
}
