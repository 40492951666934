/*
  Meetposter Web
  © R. 2024
*/

.sidebar {
  position: absolute;
  right: 8px;
  top: 10px;
  height: 65%;
  width: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0px;
  z-index: 1000;
}

.s-btn {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  flex-grow: 0;
  width: 80px;
  height: 80px;
  transition: all 0.2s ease;
  background-color: rgba(35, 35, 35, 1);
}

.s-btn:hover {
  background-color: rgb(130,39,132);
}

.s-btn-user {
  mask: var(--image_user) center center / 24px 24px no-repeat;
}

.s-btn-apple {
  mask: var(--image_apple) center center / 34px 34px no-repeat;
}

.s-btn-google {
  mask: var(--image_google) center center / 32px 32px no-repeat;
}

.s-btn-facebook {
  mask: var(--image_facebook) center center / 34px 34px no-repeat;
}

.s-btn-chat {
  mask: var(--image_chat) center center / 30px 30px no-repeat;
}

.s-btn-book {
  mask: var(--image_book) center center / 23px 23px no-repeat;
}

.s-btn-mine {
  mask: var(--image_event_my) center center / 28px 28px no-repeat;
}

.s-divider {
  max-height: 1px;
  background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.5));
  width: 100%;
}

@media (max-width: 485px) {
  .sidebar {
    display: none;
  }
}
