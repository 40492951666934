/*
  Meetposter Web
  © R. 2024
*/

.Modal {
  top: 0;
  opacity: 0.25;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  display: flex;
  align-items: center;
  transition: all .1s ease-in-out;
  background-color: #00000033;
  display: flex;
  flex-direction: column;
}

.Modal > content {
  opacity: 0;
  margin-top: 75px;
  max-width: 600px;
  transition: all .4s ease-in-out;
  background-color: #fff;
}

.Modal > content > span {
  width: 600px;
  background-color: #fff;
  position: relative;
  padding: 17px 30px;
  color: #222;
  display: flow-root;
  font-size: 14px;
}

.Modal > content > span > button {
  float: right;
  margin: 20px 0 5px;
}

.Modal > content >:first-child > button {
  position: absolute;
  width: 31px;
  height: 31px;
  right: 12px;
  top: 10px;
  background-position: 9px 5px;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  cursor: pointer;
  margin: 0px;
}

.Modal > content >:first-child {
  background-color: #F6F6F6;
  color: #444;
  height: 50px;
}

.ModalFooter {
  padding: 15px;
  background-color: #F6F6F6;
  color: #444;
  height: 60px;
}

.ModalFooter > button {
  float: right;
  width: 120px;
}

.BgOpen {
  opacity: 1 !important;
}

.ModalOpen {
  margin-top: 125px !important;
  opacity: 1 !important;
}
