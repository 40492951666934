/*
  Settings Mod
*/

.settings {
  width: 100%;
  /*
    background: var(--image_gear) center center / 128px 128px no-repeat;
  */
}

.settings-top {
  padding: 10px 20px 20px 25px;
}

.settings-top > button {
  margin-left: auto;
}

.settings-last {
  border-top: 1px dotted #999;
}

.settings-last > button {
  margin-left: auto;
  margin-top: 10px;
  width: 140px;
}

.usr-img {
	width: 125px;
	height: 125px;
	border-radius: 8px;
	font-weight: bold;
	font-size: 44px;
	line-height: 120px;
	justify-content: center;
	color: #fff;
	background-color: #cfcdf8;
	text-transform: uppercase;
	border: 1px solid rgb(217, 213, 223);
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.usr-img p {
  font-size: 14px;
}

.usr-img > button {
  position: absolute;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.15);
  display: none;
  top: 10px;
  left: 10px;
}

.usr-img:hover > button {
	display: block;
}
