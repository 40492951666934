
.uc {
  width: 100%;
  min-height: 100px;
  position: relative;
  padding-bottom: 5px;
  border-bottom: 1px dotted #999;
  margin-top: 20px;
  padding-top: 15px;
  font-size: 16.5px;
  color: #666;
}

.uc > .u-image-small {
  position: absolute;
  top: 10px;
  left: 10px;
}

.uc-name {
  padding-left: 85px;
  font-size: 15px;
  font-family: 'Roboto-Medium';
  text-transform: capitalize;
  color: #666;
  height: 25px;
  padding-right: 7px;
}

.uc-comment {
  width: 100%;
  min-height: 40px;
  padding-left: 85px;
  font-size: 14px;
  color: #666;
  margin-bottom: auto;
}

.uc-rating {
  mask: var(--image_star) 0px 3px / 12px 12px repeat-x;
  background-color: rgb(130,39,132);
  width: 60px;
  height: 20px;
  margin-right: auto;
  margin-left: 8px;
}

.uc-actions {
  width: 100%;
  height: 20px;
  flex-direction: row-reverse;
}

.cp-actions {
  flex-direction: row-reverse;
  width: 100%;
}

.cp-actions > button {
  width: 150px;
  margin-left: 10px;
}

.cp-actions-r {
  margin-right: auto;
  width: 125px;
  flex-direction: row-reverse;
  padding-top: 2px;
}

.cp-as {
  background-color: rgb(200,200,200);
  mask: var(--image_star) 1px 2px / 22px 22px repeat-x;
  height: 25px;
  width: 24px;
  cursor: pointer;
}

.cp-as1:hover, .cp-as1:hover ~ .cp-as2, .cp-as1:hover ~ .cp-as3, .cp-as1:hover ~ .cp-as4, .cp-as1:hover ~ .cp-as5 {
  background-color: rgb(125,125,125) !important;
}

.cp-as2:hover, .cp-as2:hover ~ .cp-as3, .cp-as2:hover ~ .cp-as4, .cp-as2:hover ~ .cp-as5 {
  background-color: rgb(125,125,125) !important;
}

.cp-as3:hover, .cp-as3:hover ~ .cp-as4, .cp-as3:hover ~ .cp-as5 {
  background-color: rgb(125,125,125) !important;
}

.cp-as4:hover, .cp-as4:hover ~ .cp-as5 {
  background-color: rgb(125,125,125) !important;
}

.cp-as5:hover {
  background-color: rgb(125,125,125) !important;
}

.cp-as-sel {
  background-color: rgb(130,39,132);
}
