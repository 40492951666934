/*
  © R. 2023
*/

.cols {
  min-width: 100px;
  max-height: 480px;
  flex-basis: 100%;
  flex-grow: 1;
  overflow-y: scroll;
}

.cols > div {
  border-bottom: 1px solid rgb(217, 223, 233);
  margin-top: 5px;
  padding-bottom: 6px;
  height: 70px;
  width: 100%;
  position: relative;
}

.cols > div > i {
  width: 64px;
  height: 64px;
  border: 1px solid rgb(217, 223, 233);
  display: block;
  background-size: cover;
  position: absolute;
}

.cols > div > svg {
  width: 64px;
  height: 64px;
  padding: 15px 16px 16px 10px;
  fill: rgb(145, 155, 174);
  display: block;
  position: absolute;
}

.cols > div > div {
  width: 100%;
  height: 64px;
  line-height: 64px;
  padding-left: 10px;
  font-size: 15px;
  color: #444;
  padding-left: 60px;
  padding-right: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cols > div > button {
  margin-top: 16px;
  margin-right: 2px;
  position: absolute;
  right: 0;
}

.map {
  padding-top: 12px;
  width: 100%;
}

.mapMark {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  background-color: #78278E;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
}
