/*
  App Mod
*/

.home {
  background-color: #fff;
  box-shadow: inset 0 0 175px rgba(0,0,0,0.0);
  position: relative;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-banner {
  max-width: 650px;
  min-height: 250px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  flex-grow: 1;
}

.home-banner > div {
  width: 100%;
  min-height: 75px;
  padding: 15px;
  flex-grow: 1;
}

.home-banner > div > input {
  font-size: 17px;
  height: 40px;
  padding-left: 40px;
  background-size: 18px 18px;
  background-position: 12px 11px;
  flex-grow: 1;
}

.home-banner > span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 175px;
  padding: 15px;
}

.home-banner > span > button {
  margin: 10px;
  border-radius: 3px;
  margin: 15px;
  border: 1px solid rgb(217, 223, 223);
  width: 90px;
  height: 90px;
  padding: 43px 10px 0 10px;
  justify-content: center;
  font-size: 14px;
  font-family: 'Roboto-Regular';
  color: rgb(121, 125, 124);
  position: relative;
  cursor: pointer;
  background-position: 32px 20px;
}

.home-banner > span > button:after {
  background-color: rgb(175,175,175);
  width: 90px;
  height: 90px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
}

.home-banner > span > button:hover::after  {
  background-color: rgb(160,39,132);
}

.b-explore::after {
  mask: var(--image_marker) center 20px / 26px 26px no-repeat;
}

.b-browse::after {
  mask: var(--image_browse) center 20px / 26px 26px no-repeat;
}

.b-random::after {
  mask: var(--image_random) center 20px / 26px 26px no-repeat;
}

.home-banner > div > input:focus {
	padding-left: 40px;
}

@media (max-width: 485px) {
  .home-content {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.broke {
  width: 100%;
  background: var(--image_broke) center center / 128px 128px no-repeat;
  flex-direction: column;
  position: relative;
  margin-top: -100px;
}

.broke > div {
  color: rgba(180, 180, 180, 1);
  font-size: 42px;
  justify-content: center;
  font-family: 'Roboto-Medium';
  align-items: center;
  position: relative;
  z-index: 10;
  top: 50%;
  padding-top: 90px;
}
