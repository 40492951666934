/*
  Ab
*/

.ab {
  background-color: #fff;
  box-shadow: inset 0 0 175px rgba(0,0,0,0.0);
  position: relative;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ab > * {
  color: #222;
}

.combo-1 {
  width: 80%;
  font-size: 27px;
  font-family: 'Roboto-Medium';
  padding: 5px;
}
.combo-1s {
  width: 80%;
  font-size: 20px;
  font-family: 'Raleway-Regular';
  padding: 5px;
}

.combo-1t {
  width: 80%;
  font-size: 14px;
  font-family: 'Raleway-Regular';
  margin-bottom: 50px;
  padding: 5px;
}

.combo-2 {
  width: 80%;
  font-size: 26px;
  font-family: 'Raleway-Medium';
  padding: 5px;
}
.combo-2s {
  width: 80%;
  font-size: 22px;
  font-family: 'Roboto-S';
  padding: 5px;
}

.combo-2t {
  width: 80%;
  font-size: 14px;
  font-family: 'Roboto-Regular';
  padding: 5px;
  margin-bottom: 100px;
}

.ab-btn-mask {
  mask: var(--image_twitter) center center / 28px 28px no-repeat;
}

.ab-buttons {
  flex-direction: row-reverse;
}

.ab-buttons > button {
  margin-left: 10px;
}

.ab-nav {
  position: absolute;
  left: -240px;
}

.ab-bicon {
  background-image: var(--image_twitter);
}
