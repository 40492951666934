/*
  Meetposter Web
  © R. 2024
*/

footer {
  max-height: 16px;
  width: 100%;
  position: absolute;
  top: calc(100% - 16px);
  background-color: rgba(225,225,225,1);
  justify-content: left;
  z-index: 1000;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.2s linear;
  display: flex;
}

.f-shadow {
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  background-color: rgba(255,255,255,1) !important;
}

footer > a {
  font-size: 10px;
  flex-grow: 0;
  margin-left: 10px;
  height: 16px;
  align-items: center;
  color: #888;
  padding-top: 1px;
  cursor: pointer;
}

footer > a:hover {
  color: #666;
}


.fo-r {
  position: absolute;
  right: 10px;
}
