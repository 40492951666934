/*
  Explore Mod.
*/

.explore-ph {
  width: 100%;
  background: var(--image_map_marker) center center / 128px 128px no-repeat;
}

.explore {
  width: 100%;
  position: relative;
  z-index: 0;
  padding-bottom: 0px;
  padding-top: 0px;
}

.e-map {
  width: 100%;
}

.e-mark {
  width: 120px;
  height: 120px;
  border: 2px solid #fff;
  border-radius: 10px;
  background-color: #78278E;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
  display: block;
  background-color: red;
}

.gm-style-cc > div > div {
  opacity: 0 !important;
}

.gm-style-cc > div {
  bottom: 5px;
  right: 5px;
  opacity: 0.5;
}
