/*
  Meetposter
  © R. 2024
*/

.btn-primary {
  height: 32px;
  max-height: 32px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05),inset 0px 0px 5px rgba(0, 0, 0, 0.05);
  min-width: 140px;
  padding: 0px 15px;

  /*
    flex-grow: 1;
    border: 1px solid #5F0085;
    text-shadow: 0px 0px 2px rgba(100, 100, 100, 0.15);
    background-image: linear-gradient(to bottom right, #bf0085, #442478, #58abd8);
    background-image: url('./background.jpg');

    background-color: #fff;
    background-image: linear-gradient(to bottom, #91009C, #7d0199, #7d0199);
    9713b5

    8f2c89 - PRPLR
    5D278E - PRPLR 1
    582583 - PRPLR 2
    frosted grape

    background-image: linear-gradient(145deg, #A09999, #858888);
    opacity: 0.4;
    background-image: linear-gradient(145deg, red, #8f2c89, #582583);
    linear-gradient(175deg, #78278E, #582583);
    color: rgb(130,39,132);
  */

  background: linear-gradient(175deg, #9A009F, #6D2991);
  background-position: 125%;
  display: flex;
  border-radius: 2px;
  color: #222;
  font-size: 12px;
  font-family: 'Roboto-Medium';
  color: #FFF;
  padding-top: 10px;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.075em;
  justify-content: center;
  cursor: pointer;
}

.btn-primary:hover {
  background: linear-gradient(175deg, #A300A8, #742C99);
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
}

.btn-default {
  height: 32px;
  max-height: 32px;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0);
  min-width: 100px;
  padding: 0px 25px;
  border-radius: 2px;
  justify-content: center;
  cursor: pointer;
  letter-spacing: 0.075em;
  font-family: 'Roboto-Medium';
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  color: #666;
  border: 1px solid #ccc;
  background-color: #fff;
}

.btn-default:hover {
  border: 1px solid #888;
}

/*
.btn-default
.btn-primary
.btn-success
.btn-info
.btn-warning
.btn-danger
.btn-link
*/
