/*
  Meetposter Web
  © R. 2024
*/


@font-face {
  font-family: 'Raleway-Light';
  src: local('Raleway-Light'), url('./assets/fonts/Raleway-Light.otf');
}

@font-face {
  font-family: 'Raleway-Regular';
  src: local('Raleway-Regular'), url('./assets/fonts/Raleway-Regular.otf');
}

@font-face {
  font-family: 'Raleway-Medium';
  src: local('Raleway-Medium'), url('./assets/fonts/Raleway-Medium.otf');
}

@font-face {
  font-family: 'Raleway-Bold';
  src: local('Raleway-Bold'), url('./assets/fonts/Raleway-Bold.otf');
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url('./assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
}


:root {
  /*
    Gui svg
  */

  --image_marker: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 384 512'%3E%3Cpath d='M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z'%3E%3C/path%3E%3C/svg%3E");
  --image_browse: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M160 96a96 96 0 1 1 192 0A96 96 0 1 1 160 96zm80 152V512l-48.4-24.2c-20.9-10.4-43.5-17-66.8-19.3l-96-9.6C12.5 457.2 0 443.5 0 427V224c0-17.7 14.3-32 32-32H62.3c63.6 0 125.6 19.6 177.7 56zm32 264V248c52.1-36.4 114.1-56 177.7-56H480c17.7 0 32 14.3 32 32V427c0 16.4-12.5 30.2-28.8 31.8l-96 9.6c-23.2 2.3-45.9 8.9-66.8 19.3L272 '%3E%3C/path%3E%3C/svg%3E");
  --image_random: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 384 512'%3E%3Cpath d='M40.1 467.1l-11.2 9c-3.2 2.5-7.1 3.9-11.1 3.9C8 480 0 472 0 462.2V192C0 86 86 0 192 0S384 86 384 192V462.2c0 9.8-8 17.8-17.8 17.8c-4 0-7.9-1.4-11.1-3.9l-11.2-9c-13.4-10.7-32.8-9-44.1 3.9L269.3 506c-3.3 3.8-8.2 6-13.3 6s-9.9-2.2-13.3-6l-26.6-30.5c-12.7-14.6-35.4-14.6-48.2 0L141.3 506c-3.3 3.8-8.2 6-13.3 6s-9.9-2.2-13.3-6L84.2 471c-11.3-12.9-30.7-14.6-44.1-3.9zM160 192a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm96 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z'%3E%3C/path%3E%3C/svg%3E");
  --image_map_marker: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 576 512'%3E%3Cpath d='M302.8 312C334.9 271.9 408 174.6 408 120C408 53.7 354.3 0 288 0S168 53.7 168 120c0 54.6 73.1 151.9 105.2 192c7.7 9.6 22 9.6 29.6 0zM416 503l144.9-58c9.1-3.6 15.1-12.5 15.1-22.3V152c0-17-17.1-28.6-32.9-22.3l-116 46.4c-.5 1.2-1 2.5-1.5 3.7c-2.9 6.8-6.1 13.7-9.6 20.6V503zM15.1 187.3C6 191 0 199.8 0 209.6V480.4c0 17 17.1 28.6 32.9 22.3L160 451.8V200.4c-3.5-6.9-6.7-13.8-9.6-20.6c-5.6-13.2-10.4-27.4-12.8-41.5l-122.6 49zM384 255c-20.5 31.3-42.3 59.6-56.2 77c-20.5 25.6-59.1 25.6-79.6 0c-13.9-17.4-35.7-45.7-56.2-77V449.4l192 54.9V255z'%3E%3C/path%3E%3C/svg%3E");
  --image_law: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4L325.4 293.4l-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4L272 285.3 226.7 240 168 298.7l-1.4-1.4z'%3E%3C/path%3E%3C/svg%3E");
  --image_phone: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z'%3E%3C/path%3E%3C/svg%3E");
  --image_place: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M240.1 4.2c9.8-5.6 21.9-5.6 31.8 0l171.8 98.1L448 104l0 .9 47.9 27.4c12.6 7.2 18.8 22 15.1 36s-16.4 23.8-30.9 23.8H32c-14.5 0-27.2-9.8-30.9-23.8s2.5-28.8 15.1-36L64 104.9V104l4.4-1.6L240.1 4.2zM64 224h64V416h40V224h64V416h48V224h64V416h40V224h64V420.3c.6 .3 1.2 .7 1.8 1.1l48 32c11.7 7.8 17 22.4 12.9 35.9S494.1 512 480 512H32c-14.1 0-26.5-9.2-30.6-22.7s1.1-28.1 12.9-35.9l48-32c.6-.4 1.2-.7 1.8-1.1V224z'%3E%3C/path%3E%3C/svg%3E");
  --image_event: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 640 512'%3E%3Cpath d='M74.6 373.2c41.7 36.1 108 82.5 166.1 73.7c6.1-.9 12.1-2.5 18-4.5c-9.2-12.3-17.3-24.4-24.2-35.4c-21.9-35-28.8-75.2-25.9-113.6c-20.6 4.1-39.2 13-54.7 25.4c-6.5 5.2-16.3 1.3-14.8-7c6.4-33.5 33-60.9 68.2-66.3c2.6-.4 5.3-.7 7.9-.8l19.4-131.3c2-13.8 8-32.7 25-45.9C278.2 53.2 310.5 37 363.2 32.2c-.8-.7-1.6-1.4-2.4-2.1C340.6 14.5 288.4-11.5 175.7 5.6S20.5 63 5.7 83.9C0 91.9-.8 102 .6 111.8L24.8 276.1c5.5 37.3 21.5 72.6 49.8 97.2zm87.7-219.6c4.4-3.1 10.8-2 11.8 3.3c.1 .5 .2 1.1 .3 1.6c3.2 21.8-11.6 42-33.1 45.3s-41.5-11.8-44.7-33.5c-.1-.5-.1-1.1-.2-1.6c-.6-5.4 5.2-8.4 10.3-6.7c9 3 18.8 3.9 28.7 2.4s19.1-5.3 26.8-10.8zM261.6 390c29.4 46.9 79.5 110.9 137.6 119.7s124.5-37.5 166.1-73.7c28.3-24.5 44.3-59.8 49.8-97.2l24.2-164.3c1.4-9.8 .6-19.9-5.1-27.9c-14.8-20.9-57.3-61.2-170-78.3S299.4 77.2 279.2 92.8c-7.8 6-11.5 15.4-12.9 25.2L242.1 282.3c-5.5 37.3-.4 75.8 19.6 107.7zM404.5 235.3c-7.7-5.5-16.8-9.3-26.8-10.8s-19.8-.6-28.7 2.4c-5.1 1.7-10.9-1.3-10.3-6.7c.1-.5 .1-1.1 .2-1.6c3.2-21.8 23.2-36.8 44.7-33.5s36.3 23.5 33.1 45.3c-.1 .5-.2 1.1-.3 1.6c-1 5.3-7.4 6.4-11.8 3.3zm136.2 15.5c-1 5.3-7.4 6.4-11.8 3.3c-7.7-5.5-16.8-9.3-26.8-10.8s-19.8-.6-28.7 2.4c-5.1 1.7-10.9-1.3-10.3-6.7c.1-.5 .1-1.1 .2-1.6c3.2-21.8 23.2-36.8 44.7-33.5s36.3 23.5 33.1 45.3c-.1 .5-.2 1.1-.3 1.6zM530 350.2c-19.6 44.7-66.8 72.5-116.8 64.9s-87.1-48.2-93-96.7c-1-8.3 8.9-12.1 15.2-6.7c23.9 20.8 53.6 35.3 87 40.3s66.1 .1 94.9-12.8c7.6-3.4 16 3.2 12.6 10.9z'%3E%3C/path%3E%3C/svg%3E");
  --image_blog: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M192 32c0 17.7 14.3 32 32 32c123.7 0 224 100.3 224 224c0 17.7 14.3 32 32 32s32-14.3 32-32C512 128.9 383.1 0 224 0c-17.7 0-32 14.3-32 32zm0 96c0 17.7 14.3 32 32 32c70.7 0 128 57.3 128 128c0 17.7 14.3 32 32 32s32-14.3 32-32c0-106-86-192-192-192c-17.7 0-32 14.3-32 32zM96 144c0-26.5-21.5-48-48-48S0 117.5 0 144V368c0 79.5 64.5 144 144 144s144-64.5 144-144s-64.5-144-144-144H128v96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48s-48-21.5-48-48V144z'%3E%3C/path%3E%3C/svg%3E");
  --image_user: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 448 512'%3E%3Cpath d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z'%3E%3C/path%3E%3C/svg%3E");
  --image_apple: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 380 512'%3E%3Cpath d='M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z'%3E%3C/path%3E%3C/svg%3E");
  --image_google: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 488 512'%3E%3Cpath d='M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z'%3E%3C/path%3E%3C/svg%3E");
  --image_facebook: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z'%3E%3C/path%3E%3C/svg%3E");
  --image_twitter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z'%3E%3C/path%3E%3C/svg%3E");
  --image_tiktok: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 488 512'%3E%3Cpath d='M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z'%3E%3C/path%3E%3C/svg%3E");
  --image_chat: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 640 512'%3E%3Cpath d='M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z'%3E%3C/path%3E%3C/svg%3E");
  --image_book: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 384 512'%3E%3Cpath d='M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z'%3E%3C/path%3E%3C/svg%3E");
  --image_logout: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z'%3E%3C/path%3E%3C/svg%3E");
  --image_event_my: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 576 512'%3E%3Cpath d='M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z'%3E%3C/path%3E%3C/svg%3E");
  --image_gear: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z'%3E%3C/path%3E%3C/svg%3E");
  --image_broke: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 576 512'%3E%3Cpath d='M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l44.9 74.7c-16.1 17.6-28.6 38.5-36.6 61.5c-1.9-1.8-3.5-3.9-4.9-6.3L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152zM432 224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm59.3 107.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L432 345.4l-36.7-36.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L409.4 368l-36.7 36.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L432 390.6l36.7 36.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L454.6 368l36.7-36.7z'%3E%3C/path%3E%3C/svg%3E");
  --image_date: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 448 512'%3E%3Cpath d='M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z'/%3E%3C/svg%3E");
  --image_image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z'/%3E%3C/svg%3E");
  --image_chewron_l: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 320 512'%3E%3Cpath d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z'/%3E%3C/svg%3E");
  --image_chewron_r: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E");
  --image_star: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 576 512'%3E%3Cpath d='M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z'/%3E%3C/svg%3E");
  --image_heart: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z'/%3E%3C/svg%3E");
  --image_x: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z'/%3E%3C/svg%3E");
  --image_clock: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z'/%3E%3C/svg%3E");
  --image_pen: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z'/%3E%3C/svg%3E");
  --image_mail: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23B5B5B5' viewBox='0 0 512 512'%3E%3Cpath d='M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z'/%3E%3C/svg%3E");

  /*
      Colors.
  */

  --title: rgb(100,100,100);
  --v_light: rgb(207,205,248);
  --v_medium: rgb(183, 77, 177);
  --v_dark: rgb(130,39,132);

  --white: #f2ffff;
  --black: #0e000a;
  --dgrey: #adadad;
  --mgrey: #e5e5e5;
  --lgrey: #f7f7f7;
  --orange: #ff9a03;
  --dgreen: #32a87a;
  --lgreen: #69db95;
  --blue: #3e6cff;
  --ored: #ff3503;
}

/*
  Tags.
*/

* {
  font-family: 'Roboto-Regular';
  box-sizing: border-box;
  display: block;
  color: #222;
}

title {
  display: none;
}

h1 h2 h3 h4 {
  margin: 0px;
}

style {
  display: none;
}

html {
  width: 100%;
  height: 100%;
  user-select: none;
  font-size: 16px;
  font-family: tahoma;
}

body {
  width: 100%;
  height: 100vh;
  margin: 0;
  position: relative;
  background-color: #fff;
  align-items: center;
  overflow: hidden;
}

div {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

ul {
  margin: 0;
  padding: 0;
}

ol {
  margin: 0px;
  padding: 0px;
}

li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  font-size: 25px;
  text-align: center;
}

button {
  border: 0;
  padding: 0;
  background-color: Transparent;
}

td {
  padding:0;
}

app {
  height: 100vh;
  padding-top: 74px;
  padding-bottom: 16px;
  position: relative;
}

main {
  display: block;
  overflow: auto;
  height: 100%;
  background-color: #fbfbfb;
}

textarea:focus, input:focus{
  outline: none;
}

main > section {
  display: flex;
  max-width: 1200px;
  min-height: 200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  flex-wrap: wrap;
}

main > section > .block23 {
  flex-basis: 66%;
  flex-grow: 1;
  min-height: 100px;
}

main > section > .block13 {
  flex-basis: 33%;
  flex-grow: 1;
  min-height: 100px;
}

main pre {
  width: 100%;
  color: var(--white);
  font-size: 64px;
  font-weight: 400;
  text-align: center;
  font-family: boycott;
  text-shadow: 0px 0px 80px rgba(255, 255, 255, .25);
}

footer {
  width:100%;
  height:200px;
}

#root {
  width: 100%;
  height: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.app_page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

script {
  display: none;
}

a {
	margin: 0;
	padding: 0;
}

* {
  scrollbar-width: thin;
}

/*
  Page Formating.
*/

.page-doc {
  width: 1240px;
  padding: 25px 0px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease;
  position: relative;
}

.page-title {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  padding-bottom: 30px;
  padding-top: 20px;
  color: var(--title);
  font-family: 'Roboto-Medium';
}

.page-nav {
  min-width: 240px;
  height: 1000px;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  padding-left: 30px;
  scrollbar-width: none;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.page-nav::-webkit-scrollbar {
  display: none;
}

.page-nav-cont {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 75%;
  flex-grow: 1;
  transition: all 0.25s ease;
  padding-right: 60px;
}

.page-nav > span {
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0,0,0,0.12);
  padding-bottom: 10px;
  padding-top: 20px;
  margin-right: 30px;
}

.page-nav > a {
  width: 100%;
  height: 32px;
  color: #999;
  padding-left: 10px;
  cursor: pointer;
  font-size: 15px;
}

.page-nav > a:hover {
  color: rgb(130,39,132);
}

.page-nav-s {
  color: rgb(130,39,132) !important;
}

.page-top {
  justify-content: flex-end;
  padding: 10px 20px 20px 25px;
}

/*
  Content Formationg.
*/

.cont {
  margin-bottom: auto;
  padding: 15px 25px;
  width: 100%;
}

.cont-b {
  margin-bottom: auto;
  padding: 0px 25px;
  width: 100%;
}

.cont-title {
  width: 100%;
  font-size: 20px;
  padding: 5px 0px;
  color: var(--title);
  font-family: 'Roboto-Medium';
  height: 40px;
}

.cont-text {
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: rgb(100, 100, 100);
}

.cont-text-small {
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: rgb(100, 100, 100);
}

.cont-label {
  font-size: 13px;
  margin: 0;
  font-weight: 400;
  color: #5F5F5F;
  width: 100%;
  text-align: left;
  padding: 5px 0px 7px 0px;
  height: 28px;
}

.cont-image, .cont-banner {
  width: 200px;
  height: 150px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 5px rgba(0,0,0,0.075);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--v_light);
  position: relative;
  margin-bottom: 15px;
}

.cont-banner {
  width: 100%;
}

.cont-box {
  min-width: 230px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}

.cont-box-cont {
  padding: 20px;
}

.cont-box > span {
  font-size: 16px;
  width: 100%;
  max-height: 50px;
  padding: 0px 5px;
  color: rgba(75, 70, 70, 1);
  font-family: 'Roboto-Medium';
  height: 30px;
}

.cont-box > div {
  font-size: 14px;
  width: 100%;
  max-height: 30px;
  color: rgba(55, 50, 50, 1);
  padding: 5px;
  color: #666;
  height: 30px;
}

.cont-box > div > div {
  justify-content: flex-end;
  padding-top: 2px;
  flex-grow: 1;
  color: #666;
}

.cont-box > a, .cont-box-btn {
  font-size: 14px;
  width: 100%;
  color: var(--v_medium);
  padding: 7px 5px;
  margin-top: auto;
  text-decoration: none;
  cursor: pointer;
  height: 30px;
  text-align: left;
}

.cont-box > a
{
  text-align: right;
}

.cont-box > button:hover, .cont-box > a:hover {
  text-shadow: 0px 0px 0px rgba(155, 25, 150, 0.75);
}

.cont-box-fbtn {
  width: 100%;
  height: 35px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 22px 10px;
  cursor: pointer;
  padding: 5px 48px;
  text-align: left;
  color: #666;
  font-size: 13px;
}

.cont-box-fbtn:hover {
  background-color: #FAF9F9;
}

.cont-btn {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  flex-grow: 0;
  width: 60px;
  height: 60px;
  transition: all 0.2s ease;
  background-color: rgba(65, 65, 65, 1);
}

.cont-btn:hover {
  background-color: var(--v_dark);
}

.cont-break {
  margin-top: 25px;
}

.cont-break-line {
  margin-top: 15px;
  background-color: rgb(217, 223, 233);
  margin-bottom: 15px;
  width: 100%;
  height: 1px;
}


/*
  Table formatting.
*/

.table {
  width: 100%;
  min-height: 90px;
  padding-bottom: 10px;
}

.table > div {
  width: 100%;
  height: 45px;
  line-height: 40px;
  text-align: left;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  padding: 0px 40px 0px 25px;
  background-position: right 25px top 15px;
  background-repeat: no-repeat;
  border-top: 1px dotted #aaa;
  cursor: pointer;
}

.table > div > * {
  flex-basis: 150px;
  flex-grow: 1;
  border-collapse: collapse;
  color: #888;
}

.table >:first-child {
  font-weight: regular;
  background: rgba(0, 0, 0, 0.0045);
  cursor: default;
  border: none;
}

.table >:not(:first-child):hover {
  background-color: rgba(10, 0, 10, 0.04);
}

.table >:first-child > * {
  color: #333;
  font-size: 14px;
}

.table >:not(:first-child):hover > * {
  color: #9A009F;
}

.table-pen > div {
  background: var(--image_pen) right 25px center / 12px 12px no-repeat;
}

/*
  Blocks formatting.
*/

.b-10, .b-20, .b-25, .b-30, .b-40, .b-50, .b-60, .b-70, .b-75, .b-99 {
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 50px;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}

.b-20 {
  flex-basis: 17.5%;
  width: 20%;
}

.b-25 {
  flex-basis: 20%;
  width: 20%;
}

.b-30 {
  flex-basis: 25%;
  width: 25%;
}

.b-40 {
  flex-basis: 35%;
  width: 35%;
}

.b-50 {
  flex-basis: 45%;
  width: 45%;
}

.b-60{
  flex-basis: 55%;
  width: 55%;
}

.b-70 {
  flex-basis: 65%;
  width: 65%;
}

.b-75 {
  flex-basis: 70%;
  width: 70%;
}

.b-80 {
  flex-basis: 80%;
  width: 80%;
}

.b-99 {
  flex-basis: 90%;
}

/*
  Other
*/

.clx {
  mask: var(--image_x) center center / 20px 20px no-repeat;
  background-color: #A0A0A0;
}

.clx:hover {
  background-color: rgba(225,25,25,0.7);
}

.vote {
  min-width: 55px;
  height: 20px;
  text-align: center;
  color: #666;
  font-size: 11px;
  padding-top: 2px;
  font-weight: bold;
  line-height: 15px;
  padding-right: 15px;
  overflow: hidden;
}

.vote > span {
  width: 25px;
  height: 16px;
  padding-left: 25px;
  border: 1px solid #ccc;
  mask: var(--image_heart) 6px 0px / 15px 15px no-repeat;
  background-color: rgb(130,39,132);
  cursor: pointer;
  transition: all 0.5s ease;
}

.vote-1 > span {
  transform: translateY(-25px);
}

.vote-2 > span {
  transform: translateY(25px);
  transition: all 0s ease !important;
}

.animated-in-out {
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  width: 100%;
}

.hidden {
  opacity: 0;
}
