/*
  Event Mods
  background: var(--image_event_my) center center / 128px 128px no-repeat;
*/

.event-ph {
  width: 100%;
  background: var(--image_event) center center / 128px 128px no-repeat;
}

.event {
  position: relative;
}

.e-basic {
  width: 100%;
  max-height: 250px;
  min-height: 180px;
}

.e-image {
  flex: 1;
  max-width: 200px;
  height: 180px;
}

.e-image-image {
  border: 1px dotted #000;
  margin: 0px;
  width: 100%;
  background: var(--image_event) center center / 92px 92px no-repeat;
  background-color: rgba(140, 135, 120, 1);
  opacity: 0.25;
}

.e-image > button {
	position: absolute;
	box-shadow: 0px 0px 5px rgba(0,0,0,0.15);
	display: none;
	top: 30px;
	left: 40px;
}

.e-image:hover > button {
	display: block;
}

.e-list {
  flex: 1;
  flex-direction: column;
  padding-left: 20px;
  margin-bottom: auto;
}

.e-list > span {
  font-size: 26px;
  color: #666;
  padding: 10px;
  font-family: 'Roboto-Bold';
  min-height: 55px;
  width: 100%;
  flex: 0;
}

.e-list > div {
  font-size: 16px;
  min-height: 40px;
  width: 100%;
  flex: 0;
  color: #666;
  padding: 10px 40px;
}

.e-list-date {
  background: var(--image_date) 12px 11px / 16px 16px no-repeat;
}

.e-list-location {
  background: var(--image_marker) 10px 10px / 18px 18px no-repeat;
}

.e-list-time {
  background: var(--image_clock) 12px 11px / 16px 16px no-repeat;
}

.e-list > div:last-child {
  min-height: 10px !important;
}

.e-list-divider {
  width: calc(100% + 25px);
  height: 40px;
  position: relative;
  margin-left: 0;
  padding-left: 0;
  margin-top: 30px;
  border-top: 1px dotted rgba(130,39,132,1);
}

.e-list-divider > span {
  position: absolute;
  right: 20px;
  font-size: 32px;
  text-align: right;
  color: rgba(130,39,132,0.75);
  padding-top: 5px;
  font-family: 'Roboto-Medium';
  background-color: #fbfbfb;
  top: -25px;
  padding-left: 20px;
}

.e-acts {
  width: 100%;
  min-height: 80px;
  padding-bottom: 10px;
}

.e-acts > span {
  font-size: 19px;
  color: #444;
  border-bottom: 1px solid #eee;
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.e-acts > div {
  width: 100%;
  min-height: 40px;
  position: relative;
  padding-left: 55px;
}

.e-acts > div > i {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 16px;
  top: 5px;
  border: 3px solid #aaa;
  border-radius: 10px;
  background-color: white;
  z-index: 100;
}

.e-acts-line {
  position: absolute;
  top: 20px;
  left: 24px;
  width: 4px;
  height: 80px;
  background-color: #aaa;
}

.e-acts > div > span {
  font-size: 15px;
  padding-top: 5px;
  color: #444;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.e-acts > div > span > div {
  padding-left: 20px;
}

.e-acts > div > span > button {
  font-size: 13px;
  height: 20px;
  margin-left: auto;
  color: rgba(155, 25, 150, 0.75);
  padding-right: 25px;
  cursor: pointer;
}

.e-acts > div > span > button:hover {
  text-shadow: 0px 0px 0px rgba(155, 25, 150, 0.75);
}

.e-actions {
  flex: 1;
  flex-basis: 25%;
  min-height: 140px;
  border-top: 1px solid #eee;
  padding: 10px 20px 0 5px;
}

.e-actions > span {
  margin-left: auto;
  padding-top: 5px;
  font-family: 'Roboto-Medium';
  font-size: 25px;
  color: #444;
  padding-top: 18px;
  padding-right: 20px;
}

.e-actions > div {
  margin-left: 10px;
  flex-direction: column;
  padding-top: 5px;
}

.e-btn-twitter {
  mask: var(--image_twitter) center center / 28px 28px no-repeat;
}

.e-btn-facebook {
  mask: var(--image_facebook) center center / 28px 28px no-repeat;
}

.e-btn-tiktok {
  mask: var(--image_tiktok) center center / 28px 28px no-repeat;
}

.e-edit-items {
  margin-top: 10px;
  min-height: 50px;
}

.e-edit-items > button {
  margin-left: auto;
}

.e-edit-items > div {
  width: 100%;
}

.e-extra {
  flex-basis: 50%;
  flex-grow: 1;
  margin-left: 20px;
  max-height: 0px;
}

.measure {
  position: absolute;
  width: 1280px;
  height: 150%;
  margin-left: calc(50vw - 640px);
  top: 50px;
  border: 1px solid blue;
}

.event-saved-nl {
  background: var(--image_book) center center / 128px 128px no-repeat;
}

.event-edit > .page-doc {
  padding-bottom: 50px;
}

.event-mine {
  overflow-y: scroll;
}
