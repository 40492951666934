/*
  Blog Mod
  background: var(--image_blog) center center / 128px 128px no-repeat;
*/

.blog {
  width: 100%;
  padding-top: 10px;
}

.post-title {
  font-size: 20px;
  color: rgb(60, 60, 60);
  text-transform: capitalize;
  width: 100%;
  height: 32px;
}

.post-date {
  font-size: 12px;
  color: rgb(80,80,60);
  text-transform: capitalize;
  text-align: right;
  line-height: 10px;
  width: 100%;
  height: 35px;
}

.post-text {
  font-size: 14px;
  color: rgb(80,80,100);
  text-align: justify;
  padding-bottom: 20px;
}

.post-act {
  width: 100%;
  border-top: 1px dotted rgb(125, 125, 125);
  padding-top: 10px;
  flex-direction:row-reverse;
}
