/*
  Contact mod
  background: var(--image_contact) center center / 128px 128px no-repeat;
*/

.contact {
  width: 100%;
}

.contact-cubes {
  flex-direction: column;
  padding-top: 40px;
  margin-bottom: auto;
}

.contact-phone, .contact-email {
  user-select: text;
  min-width: 180px;
  height: 180px;
  background-color: #fff;
  margin-left: auto;
  justify-content: center;
  align-items:center;
  padding-top: 120px;
  color: rgba(90, 90, 90, 1);
  font-size: 14px;
}

.contact-phone {
  background: var(--image_phone) center 40px / 54px 54px no-repeat, #fff;
}

.contact-email {
  background: var(--image_mail) center 40px / 54px 54px no-repeat, #fff;
}

.contact-actions {
  width: 100%;
  padding-top: 20px;
  justify-content: flex-end;
}

.contact-sent {
  color: rgba(90, 90, 90, 1);
  font-size: 23px;
  height: 380px;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  line-height: 50px;
  padding-right: 70px;
}
