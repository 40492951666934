/*
  Privacy Mod
  background: var(--image_law) center center / 128px 128px no-repeat;
*/

.privacy {
  width: 100%;
  padding-bottom: 50px;
}

.privacy > div > span {
  width: 100%;
}

.privacy > div > p {
  width: 100%;
  justify-content: stretch;
  font-size: 14px;
  color: rgb(120,120,120);
  line-height: 28px;
  text-align: justify;
}
