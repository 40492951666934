/*
  Browse Mod
*/

.browse-nl {
  width: 100%;
  background: var(--image_browse) center center / 128px 128px no-repeat;
}

.b-doc {
  width: 100%;
}

.b-nav {
  margin-left: 40px;
}

.b-list-divider {
  margin-right: 75px;
}
