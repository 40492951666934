/*
  Meetposter Web
  © R. 2023
*/

header {
  position: absolute;
  top: 0;
  min-height: 4px;
  width: 100%;
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
}

header .progres {
  width: 100%;
  height: 4px;
  padding-bottom: 1;
  background: linear-gradient(175deg, #78278E, #582583);
}

header .progres > div {
  height: 5px;
  width: 0%;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05),inset 0px 0px 5px rgba(0, 0, 0, 0.05);
	background: linear-gradient(90deg, #78278E, #582583);
	background-position: 125%;
  position: absolute;
  left: 0;
  top: 60px;
  transition: width 0.2s linear;
}

header > span {
  max-width: 1240px;
  height: 0px;
  margin: auto;
  display: flex;
  transition: all 0.2s ease;
  overflow: hidden;
  position: relative;
}

.h-left {
  width: 395px;
  flex-grow: 0;
  padding: 8px 10px 5px 20px;
}

.h-btn {
  width: 60px;
  height: 60px;
  background-position: 18px 17px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  cursor: pointer;
  position: relative;
}

.h-btn:after {
  background: rgb(130,39,132);
  width: 60px;
  height: 60px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  mask-position: 18px 17px !important;
  mask-repeat: no-repeat !important;
  mask-size: 24px 24px !important;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  opacity: 0;
  transition: all 0.2s ease;
}

.h-active::after {
  opacity: 1 !important;
  background: rgb(120,120,120) !important;
}

.h-btn:hover::after  {
  opacity: 1;
}

.h-mid {
  padding-top: 19px;
  flex-basis: 50%;
  flex-grow: 1;
}

.h-right {
  padding: 8px 15px 0 15px;
  width: 165px;
  position: relative;
  overflow: hidden;
  flex-grow: 0;
}

.h-right > .InputText {
  margin-top: 11px;
}

.h-right-e {
  width: 220px;
  padding-right: 10px;
}

.h-border {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 1px;
}

.h-menu {
  width: 100%;
  height: 1px;
  margin: auto;
  position: absolute;
  z-index: -1;
}

.h-menu-wrap {
  max-width: 1290px;
  transition: all 0.2s ease;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  margin: auto;
  justify-content: flex-end;
  padding: 0px 5px 0 0;
  height: 0px;
}

.h-menu-open {
  height: 360px !important;
}


.h-menu-cont {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  width: 280px;
  height: 320px;
  background-color: rgba(255, 255, 255, 1);
  flex-grow: 0;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 5px;
  margin-top: 1px;
  cursor: pointer;
}

.h-menu-item {
  width: 100%;
  font-size: 14px;
  color: #999;
  padding: 0 0 1px 64px;
  align-items: center;
  font-family: 'Roboto-Medium';
  height: 55px;
  transition: all 0.15s ease;
  position: relative;
}

.h-menu-item:hover {
  background-color: rgba(248, 248, 248, 1);
  color: rgb(130,39,132);
}

.h-menu-item:after {
  background: rgb(130,39,132);
  width: 55px;
  height: 55px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  opacity: 0;
  transition: all 0.2s ease;
}

.h-menu-item:hover::after  {
  opacity: 1;
}

.h-menu-prof {
  border-bottom: 1px dotted #ccc;
  background: var(--image_gear) 22px 18px / 18px 18px no-repeat;
}

.h-menu-prof::after {
  mask: var(--image_gear) 22px 18px / 18px 18px no-repeat;
}

.h-menu-chat {
  border-bottom: 1px dotted #ccc;
  background: var(--image_chat) 20px 16px / 21px 21px no-repeat;
}

.h-menu-chat::after {
  mask: var(--image_chat) 20px 16px / 21px 21px no-repeat;
}

.h-menu-saved {
  border-bottom: 1px dotted #ccc;
  background: var(--image_book) 22px 19px / 16px 16px no-repeat;
}

.h-menu-saved::after {
  mask: var(--image_book) 22px 19px / 16px 16px no-repeat;
}

.h-menu-myevt {
  border-bottom: 1px dotted #ccc;
  background: var(--image_event_my) 20px 16px / 21px 21px no-repeat;
}

.h-menu-myevt::after {
  mask: var(--image_event_my) 20px 16px / 21px 21px no-repeat;
}

.h-menu-logout {
  border-top: 1px dotted #ccc;
  margin-top: 40px;
  background: var(--image_logout) 20px 17px / 21px 21px no-repeat;
}

.h-menu-logout::after {
  mask: var(--image_logout) 20px 17px / 21px 21px no-repeat;
}

header *.logo_s {
  cursor: pointer;
  margin-right: 5px;
}

.h-explore {
  background-image: var(--image_marker);
}

.h-explore::after {
  mask: var(--image_marker);
}

.h-browse {
  background-image: var(--image_browse);
}

.h-browse::after {
  mask: var(--image_browse);
}

.h-random {
  background-image: var(--image_random);
}

.h-random::after {
  mask: var(--image_random);
}

.h-user {
  background-image: var(--image_user);
}

.h-user::after {
  mask: var(--image_user);
}
