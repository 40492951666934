.es-more {
  font-size: 20px;
  flex-basis: 100%;
  padding:0 0 10px;
  width: 100%;
  position: relative;
}

.es-more-cont {
  height: 290px;
  flex-basis: 800px;
  margin-bottom: 10px;
  padding: 0 0px;
  flex-wrap: nowrap;
  margin-left: 0px;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.es-more-cont > span {
  display: flex;
  flex-grow: 1;
  margin-left: -10px;
  transition: all 0.2s ease;
}

.es-more-left {
  height: 300px;
  width: 36px;
  mask: var(--image_chewron_l) 5px center / 28px 28px no-repeat;
  background-color: rgb(120,120,120);
  cursor: pointer;
  position: absolute;
  left: -45px;
  top: 20px;
}

.es-more-right {
  height: 300px;
  width: 36px;
  mask: var(--image_chewron_r) 0px center / 28px 28px no-repeat;
  background-color: rgb(120,120,120);
  cursor: pointer;
  position: absolute;
  right: -45px;
  top: 20px;
}

.es-more-left:hover, .es-more-right:hover {
  opacity: 1 !important;
  background: rgb(130,39,132) !important;
}

.e-more-item {
  height: 300px;
  max-width: 270px;
  border: 1px dotted #111;
}
