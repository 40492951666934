/*
  User comps
*/

.u-image-small {
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  font-weight: bold;
  font-size: 20px;
  line-height: 60px;
  justify-content: center;
  background-color: rgba(140, 135, 120, 0.3);
  color: #ebebeb;
}

.u-login {
  height: 140px;
  width: 100%;
  padding: 15px 0 0 0;
  justify-content: center;
}

.u-login > button {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  flex-grow: 0;
  width: 100px;
  height: 100px;
  transition: all 0.2s ease;
  background-color: rgba(35, 35, 35, 1);
}

.u-login > button:hover  {
  background-color: rgb(130,39,132);
}

.u-btn-apple {
  mask: var(--image_apple) center center / 40px 40px no-repeat;
}

.u-btn-google {
  mask: var(--image_google) center center / 35px 35px no-repeat;
}

.u-btn-facebook {
  mask: var(--image_facebook) center center / 38px 38px no-repeat;
}
