/*
  Meetposter
  © R. 2024
*/

.logo {
  max-width: 300px;
  max-height: 60px;
  display: flex;
}

.logo_l {
  position: absolute;
  width: 384px;
  height: 128px;
  background: url('./logo_l.png') bottom center / 384px 128px no-repeat;
  left: 50%;
  top: 25%;
  margin: -64px 0 0 -192px;
}

.logo_s {
  width: 180px;
  height: 60px;
  background: url('./logo_l.png') center -2px / 180px 60px no-repeat;
}

@media (max-width: 485px) {
  .logo_l {

  }
}
